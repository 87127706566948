<template lang="pug">
div
  .row
    .col-12.text-end
      .form-group
        label(for='role') Organização
        select.form-select.ml-3(
          @change='selectOrganization'
          style='width: auto; display: inline; margin-left: 1rem'
          v-model='selectedOrganization'
        )
          option(value='') Selecione uma Opção
          option(
            :value='organization.id'
            v-for='(organization, index) in organizations'
          ) {{ organization.name }}
  .row(v-if='subscription')
    .col-12.col-sm-12.col-lg-4
      .card.mb-4
        .card-body(style='min-height: 120px')
          .row
            .col-12
              img.img.mb-3(src='/images/logo.svg' style='max-height: 60px')
            .col-12
              p.mb-0(style='font-size: 0.8rem')
                | Plataforma de gestão de documentos e assinaturas eletrônicas
    .col-12.col-sm-6.col-lg-4
      .card.mb-4
        .card-body(style='min-height: 120px')
          .row
            .col-12
              h6(style='color: #013d52; font-weight: 600') {{ subscription.name }}
              .price(style='color: #01afec; margin-top: 0.7rem')
                //- span(style='font-weight: 600;') R$
                span(style='font-weight: 700; font-size: 1.6rem') {{ subscription.price }}
                span /mês
              small(
                style='position: absolute; right: 1rem; top: 55px'
                v-if='subscription.paymentMethod !== "free"'
              )
                | Renova em {{ subscription.nextPaymentDate | dateTime }}
              .text(
                style='position: absolute; right: 1rem; top: 78px'
                v-if='subscription.paymentMethod !== "free"'
              )
                a.d(
                  data-bs-target='#cancel-subscription-modal'
                  data-bs-toggle='modal'
                  href='#'
                  style='color: #d00a00'
                ) Cancelar plano
    .col-12.col-sm-6.col-lg-4
      .card.mb-4
        .card-body(style='min-height: 120px')
          .row
            .col-12
              h6(style='font-weight: 600') Forma de pagamento
              div(
                style='margin-bottom: 0.7rem'
                v-if='subscription.paymentMethod !== "free"'
              )
                small {{ subscription.card.banner }} •••• {{ subscription.card.last_four_digits }}
              div(style='margin-bottom: 0.7rem' v-else)
                small Plano gratuito
              p.mb-0.text-end(v-if='subscription.paymentMethod !== "free"')
                router-link(:to='linkChangeCard')
                  | Alterar cartão
  .row.mt-5(v-if='paymentHistory.length > 0')
    .col-12
      h5.mb-2(style='color: #013d52') Histórico
    .col-12
      .table-cards.shadow
        .row.px-0.py-2(:key='index' v-for='(item, index) in paymentHistory')
          .col-12.col-md-4
            strong -
          .col-12.col-md-2
            div(v-if='item.status === "paid"')
              icon.me-2(name='circle-check' style='color: #39d5c5')
              span.text Pago
            div(v-if='item.status === "rejected"')
              icon.me-2.icon--rejected(name='ban')
              span.text Rejeitado
          .col-12.col-md-3
            span.text Total de
              strong {{ item.value.formatted }}
          .col-12.date.col-md-2
            span.text {{ item.created_at | dateTime }}
  cancel-subscription-modal(@refresh='refreshSubscriptionData')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import BillingHttp from '@/services/BillingHttp';
import OrganizationService from '@/modules/account/services/http/OrganizationService';
import CancelSubscriptionModal from '@/modules/account/components/organisms/modals/CancelSubscriptionModal.vue';
import LocalStorage from '@/services/LocalStorage';

export default {
  name: 'PlanPage',
  components: {
    Icon,
    CancelSubscriptionModal,
  },
  data() {
    return {
      subscription: '',
      paymentHistory: '',
      organizations: [],
      selectedOrganization: '',
    };
  },
  async created() {
    this.$loading(true);
    try {
      this.$store.dispatch('page/setTitle', 'planos');
      this.$store.dispatch('page/setHead', { title: 'Planos' });
      this.$store.dispatch('page/setClass', 'account plans');
      await this.fetchOrganizations();
      await this.getSelectedOrganization();
      await this.refreshSubscriptionData();
    } finally {
      this.$loading(false);
    }
  },
  computed: {
    linkChangeCard() {
      return `/account/billing/subscription/${this.subscription.id}/${this.selectedOrganization}`;
    },
  },
  methods: {
    async refreshSubscriptionData() {
      await this.getSubscription();
      await this.getSubscriptionPayments();
    },
    async fetchOrganizations() {
      await OrganizationService.list().then((response) => {
        const { data } = response.data;
        data.forEach((organization) => {
          if (
            organization.is_owner ||
            organization.permissions.manage_billing
          ) {
            this.organizations.push(organization);
          }
        });
      });
    },
    getSelectedOrganization() {
      const organizaçãoPreSelecionada = this.organizations.find(
        (organization) => organization.id === LocalStorage.get('organization')
      );
      if (organizaçãoPreSelecionada || this.selectedOrganization === '') {
        this.selectedOrganization = organizaçãoPreSelecionada.id;
      }
    },
    async selectOrganization(e) {
      this.$loading(true);
      LocalStorage.set('organization', e.target.value);
      this.selectedOrganization = e.target.value;
      await this.refreshSubscriptionData();
      this.$loading(false);
    },
    async getSubscription() {
      await BillingHttp.getSubscription().then((ret) => {
        const { data } = ret;
        this.subscription = {
          id: data.plan.id,
          name: data.plan.name,
          card: {
            banner: data.properties ? data.properties.card_banner : '',
            last_four_digits: data.properties
              ? data.properties.card_last_four_digits
              : '',
          },
          price: data.plan.price.formatted,
          nextPaymentDate: data.next_recurrence_at,
          paymentMethod: data.payment_method,
        };
      });
    },
    async getSubscriptionPayments() {
      await BillingHttp.getSubscriptionPayments().then((ret) => {
        this.paymentHistory = ret.data.data ? ret.data.data : null;
      });
    },
  },
};
</script>
