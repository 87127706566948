<template lang="pug">
modal#cancel-subscription-modal(
  :buttonColor='buttonColor'
  :close='close'
  :loading='loading'
  @closed='onCloseModal'
  @opened='onOpenModal'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Cancelar'
  ref='modal'
  title='Cancelar plano'
)
  p Ao excluir seu plano, poderá utilizá-lo até seu fechamento, depois voltará para nosso
    | plano gratuito. Essa ação não poderá ser desfeita.
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import BillingHttp from '@/services/BillingHttp';
import formHandler from '@/modules/account/common/formHandler';
import alert from '@/modules/account/common/alert';

export default {
  components: {
    Modal,
    ImageLoader,
  },
  data() {
    return {
      loading: true,
      close: false,
      buttonColor: true,
    };
  },
  computed: {
    modalTitle() {
      return this.title;
    },
  },
  methods: {
    onOpenModal() {
      this.loading = false;
    },
    onCloseModal() {
      this.organization = {};
      this.name = '';
      this.logo = '';
      this.close = false;
      this.loading = true;
    },
    submit() {
      formHandler.submit(this, () => BillingHttp.cancelSubscription(), {
        successHandler: (response) => {
          if (response.status === 204) {
            this.loading = false;
            this.close = true;
            alert.fireAlert('Plano cancelado com sucesso', {
              classes: 'alert-success',
              styles:
                'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
              icon: 'circle-check',
            });
            this.$refs.modal.close();
            this.$emit('refresh');
          }
        },
      });
    },
  },
};
</script>
