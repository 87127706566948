<template lang="pug">
.table-card(:class='`d-${breakpointSize}-table`')
  .table-card__head.d-none(
    :class='`d-${breakpointSize}-table-row`'
    v-if='$slots["table-head"]'
  )
    slot(name='table-head')
  template(v-for='(item, index) in totalItems')
    .table-card__item.table-card__item--card.d-block(
      :class='`d-${breakpointSize}-none`'
    )
      slot(:name='`card-${index}`')
    .table-card__item.table-card__item--row.d-none(
      :class='`d-${breakpointSize}-table-row`'
    )
      slot(:name='`table-row-${index}`')
</template>
<script>
export default {
  name: 'CardTable',
  props: {
    totalItems: {
      type: Number,
    },
    breakpointSize: {
      type: String,
      default: 'md',
      validator: (value) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
      required: false,
    },
  },
};
</script>
<style lang="stylus">
@media (min-width: 576px)
  .d-sm-table-row
    display: table-row-group!important
@media (min-width: 768px)
  .d-md-table-row
    display: table-row-group!important
@media (min-width: 992px)
  .d-lg-table-row
    display: table-row-group!important
@media (min-width: 1200px)
  .d-xl-table-row
    display: table-row-group!important

.table-card
  width: 100%
.table-card__item
  position: relative
.table-card__head
  width: 100%
.table-card__head
  th
    font-size: 0.9em
    font-weight: normal
    color: #013d52
    padding: 1em
.table-card__item--card
  border: 1px solid #ccc
  margin-bottom: -1px
  padding: 1rem
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0)
  transition: box-shadow .1s
  &:hover
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16)
  > a
    color: inherit
    &:hover
      opacity: 1
.table-card__item--row
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0)
  transition: box-shadow .1s
  &:hover
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16)
  &:last-child
    td
      border-bottom: 1px solid #dee2e6
  > a
    color: inherit
    display: table-row
    &:hover
      opacity: 1
  td
    padding: .5em 1em
    font-size: .9em
    border-top: 1px solid #dee2e6
    vertical-align: middle
    &:first-child
      border-left: 1px solid #dee2e6
    &:last-child
      border-right: 1px solid #dee2e6
</style>
