// import LocalStorage from '@/services/LocalStorage';
import Http from './Http';

const client = new Http('');

client.getPlansList = () => client.get('', '', '/plans');
client.getSubscription = () => client.get('', '', '/subscription');
client.updateSubscription = (data) =>
  client.put('', data, '/subscription/subscribe');
client.cancelSubscription = () => client.delete('', '', '/subscription/cancel');

client.subscriptionsList = () => client.get('', '', '/subscriptions');
client.getSubscriptionPlan = (id) => client.get(id, '', '/subscriptions');
client.subscriptionsList = () => client.get('', '', '/subscriptions');
client.getSubscriptionPayments = () =>
  client.get('', '', '/subscription/invoices');
client.subscribePlan = (planId, data) =>
  client.post(data, `plans/${planId}/subscriptions`);
client.updateSubscribePlan = (subId, data) =>
  client.put(`${subId}/change-plan`, data, 'subscriptions/');

export default client;
